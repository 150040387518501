<template>
  <div class="finance-invoice-review">
    <en-table-layout :tableData="tableData.data">
      <template slot="header">
        <div style="padding:10px 15px">
          <h3>开卡发票申请流程：</h3>
          <div class="headerStep">
            <p class="stepImage">
              <img src="../../../assets/step/step1.png" alt="">
              <span>移动开卡商城前台提交开票申请</span>
            </p>
            <p class="stepLine">
              <span class="line"></span>
              <span class="arrow-right"></span>
            </p>
            <p class="stepImage">
              <img style="width:66px" src="../../../assets/step/step2.png" alt="">
              <span>销售人员后台进行发票信息补录</span>
            </p>
            <p class="stepLine">
              <span class="line"></span>
              <span class="arrow-right"></span>
            </p>
            <p class="stepImage">
              <img style="width:92px" src="../../../assets/step/step3.png" alt="">
              <span>财务人员进行审核并开具发票</span>
            </p>
            <p class="stepLine">
              <span class="line"></span>
              <span class="arrow-right"></span>
            </p>
            <p class="stepImage">
              <img src="../../../assets/step/step4.png" alt="">
              <span>发票开具完成</span>
            </p>
          </div>
        </div>
      </template>
      <template slot="toolbar">
        <el-form-item label="状态" class="col-auto">
          <el-select style="width: 110px" v-model="advancedForm.status" placeholder="请选择" clearable>
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="申请开票类型" class="col-auto">
          <el-select style="width: 110px" v-model="advancedForm.invoice_type" placeholder="请选择" clearable>
            <el-option v-for="item in optionsTypes" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>

        <el-form-item label="提交时间" class="col-auto">
          <el-date-picker v-model="order_time_range" type="daterange" style="width: 250px" range-separator="-"
            start-placeholder="开始日期" end-placeholder="结束日期" @change="handleDateChange" />
        </el-form-item>
        <el-form-item>
          <el-input style="width: 290px;" size="small" placeholder="请输入关键字进行搜索" v-model.trim="params.keyWord" clearable>
            <el-select v-model="key_word" slot="prepend" style="width: 130px;">
              <el-option label="客户名称" value="customer_name"></el-option>
              <el-option label="提交批次号" value="batch_sn"></el-option>
              <el-option label="申请人名称" value="applicant_name"></el-option>
              <el-option label="申请人手机号" value="applicant_mobile"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button native-type="submit" @click.stop.prevent="searchEvent" size="small" type="primary">搜索</el-button>
        </el-form-item>
      </template>

      <template slot="table-columns">
        <el-table-column prop="batch_sn" show-overflow-tooltip label="提交批次号" width="150" fixed="left" />
        <el-table-column prop="invoice_type" show-overflow-tooltip label="申请开票类型" width="190" fixed="left">
          <template slot-scope="scope">
            {{ scope.row.invoice_type | invoiceTypeFilter }}
          </template>
        </el-table-column>
        <el-table-column prop="customer_name" label="客户名称" fixed="left" show-overflow-tooltip />
        <el-table-column prop="create_time" label="提交时间" width="160">
          <template slot-scope="scope">{{ scope.row.create_time | unixToDate('yyyy-MM-dd hh:mm:ss') }}</template>
        </el-table-column>
        <el-table-column prop="applicant_name" label="申请人名称" width="200" />
        <el-table-column prop="applicant_mobile" label="申请人手机号" width="170" />
        <el-table-column prop="status" label="状态" width="100">
          <template slot-scope="scope">
            {{ scope.row.status | statusFilter }}
          </template>
        </el-table-column>
        <!--操作-->
        <el-table-column label="操作" width="150" fixed="right">
          <template slot-scope="{row}">
            <el-button v-if="row.status === 0" size="mini" type="primary" @click="handleGoodsAudit(row)">
              审核
            </el-button>
            <el-button v-if="row.status === 3" size="mini" type="primary" @click="handleExpress(row)">
              邮寄
            </el-button>

            <el-button size="mini" type="primary" @click="openDetail(row)">查看
            </el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination v-if="tableData" slot="pagination" @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange" :current-page="tableData.page_no" :page-size="tableData.page_size"
        :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background
        :total="tableData.data_total"></el-pagination>
    </en-table-layout>

    <!--审核 dialog-->
    <x-dialog :proxy="examinationDialog">
      <el-form style="padding:20px" :model="goodsAuditForm" :rules="goodsAuditRules" ref="goodsAuditForm"
        label-width="110px">
        <!--是否通过=-->
        <el-form-item label="是否通过" prop="pass">
          <el-radio-group v-model="goodsAuditForm.pass">
            <el-radio :label="1">通过</el-radio>
            <el-radio :label="2">不通过</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="goodsAuditForm.pass === 2" label="拒绝原因" prop="reason">
          <el-input v-model.trim="goodsAuditForm.reason" type="textarea" maxlength="100" :rows="2"
            placeholder="拒绝原因最多不超过100字。" />
        </el-form-item>
        <el-form-item v-if="goodsAuditForm.pass === 1 && [1, 2].includes(goodsAuditForm.invoice_type)" label="电子发票上传"
          prop="files">
          <el-upload accept=".jpg, .png,.jpeg, .pdf" :multiple="true" class="avatar-uploader"
            :action="`${MixinUploadApi}?scene=file`" :show-file-list="false" :on-success="uploadSuccess"
            :before-upload="beforeUpload">
            <el-button size="mini" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip" style="line-height: 15px;">
              说明：<br />
              1、请上传jpg、png、PDF格式的文件，大小不超5MB ；<br />
              2、文件数量不限，1次上传最多允许同时上传10个文件；
            </div>
          </el-upload>
          <div style="display: flex;flex-wrap: wrap; ">
            <div class="imgBox" v-for="(item, index) in fileList" :key="index">
              <i class="el-icons el-icon-circle-close" @click="removeFile(index)"></i>
              <img style="width: 100px; height: 100px;" src="@/assets/images/pdf.png" v-if="item.file_type === 'pdf'">
              <img v-else style="width: 70px; height: 70px;" :src="item.url" alt="">
              <br>
              <el-tooltip class="fileName" effect="dark" :content="item.file_name" placement="top-start">
                <span>{{ item.file_name }}</span>
              </el-tooltip>
            </div>
          </div>
        </el-form-item>
      </el-form>
    </x-dialog>
    <!--邮寄 dialog-->
    <x-dialog :proxy="expressDialog">
      <el-form style="padding:20px" :model="expressForm" :rules="expressRules" ref="expressForm" label-width="120px">
        <!--是否通过=-->
        <el-form-item label="物流公司" prop="logi_id">
          <el-select v-model="expressForm.logi_id" @change="handleExpressCompanyChange">
            <el-option v-for="item in expressCompany" :key="item.logi_id" :label="item.name"
              :value="item.logi_id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="物流单号" prop="delivery_no">
          <el-input type="text" placeholder="请输入物流单号" clearable :maxlength="50"
            v-model="expressForm.delivery_no"></el-input>
        </el-form-item>
        <el-form-item label="收件人手机号" prop="receive_phone">
          <el-input type="text" placeholder="请输入收件人手机号" clearable :maxlength="11"
            v-model="expressForm.receive_phone"></el-input>
        </el-form-item>
      </el-form>
    </x-dialog>
  </div>
</template>

<script>
import * as API_SettingCustomer from '@/api/settingCustomer';
import * as API_Account from '@/api/account';
import EnTableLayout from '../../../../ui-components/TableLayout/src/main';
import XDialog from "@/components/x-dialog/x-dialog";
import { $xDialog } from "@/components/x-dialog/dialog.proxy";
import { RegExp } from "@/../ui-utils";
export default {
  name: 'cardAndInvoiceReview',
  components: { EnTableLayout, XDialog },
  filters: {
    statusFilter (status) {
      const statusMap = {
        '0': '待审核',
        '3': '待邮寄',
        '2': '已邮寄',
        '1': '已开票',
        '-1': '已拒绝'
      }
      return statusMap[status]
    },
    invoiceTypeFilter (type) {
      const temp = {
        1: '普通电子发票',
        2: '增值税专用发票（电子）',
        3: '普通纸质发票',
        4: '增值税专用发票（纸质）'
      }
      return temp[type]
    },
  },
  data () {
    return {
      fileList: [],
      examinationDialog: $xDialog.create({
        title: "发票审核",
        width: "50vw",
        showClose: true,
        displayFooterExtras: true,
        beforeConfirm: this.submitGoodsAuditForm
      }),
      expressDialog: $xDialog.create({
        title: "发票邮寄",
        width: "40vw",
        showClose: true,
        displayFooterExtras: true,
        beforeConfirm: this.submitExpressForm
      }),
      idsList: [],
      order_time_range: [],
      // <!-- ; 待审核;1已通过;2已经邮寄 -->
      options: [
        {
          value: '',
          label: '全部'
        },

        {
          value: 0,
          label: '待审核'
        },
        {
          value: '3',
          label: '待邮寄'
        },
        {
          value: '2',
          label: '已邮寄'
        },
        {
          value: '1',
          label: '已开票'
        },
        {
          value: '-1',
          label: '已拒绝'
        },
      ],
      optionsTypes: [
        {
          value: '',
          label: '全部'
        },

        {
          value: 1,
          label: '普通电子发票'
        },
        {
          value: 3,
          label: '普通纸质发票'
        },
        {
          value: 2,
          label: '增值税专用发票-电子版'
        },
        {
          value: 4,
          label: '增值税专用发票-纸质版'
        },
      ],
      // 列表loading状态
      loading: false,
      key_word: 'customer_name',
      // 列表参数
      params: {
        page_no: 1,
        page_size: 20,
        status: '',
        keyWord: "",
      },
      // 商品数据
      tableData: {},
      // 审核商品 表单
      goodsAuditForm: {
        record_id: '',
        reason: '',
        pass: 1,
        invoice_type: '',
        files: []
      },
      // 审核商品 表单校验
      goodsAuditRules: {
        files: [
          {
            required: true,
            message: '请上传发票文件',
            trigger: 'blur'
          }
        ]
      },

      // 高级搜索数据
      advancedForm: {
        status: '', // 审核状态
        invoice_type: '',//申请开票类型
      },
      //查看商品变更记录
      dialogGoodspass: false,
      tableDatapass: [],

      //邮寄相关字段
      dialogExpressVisible: false,
      expressForm: {
        logi_id: '',
        logi_name: '',
        delivery_no: '',
        record_id: ''
      },
      expressRules: {
        logi_id: [
          {
            required: true,
            message: '请选择物流公司',
            trigger: 'change'
          }
        ],
        delivery_no: [
          {
            required: true,
            message: '请填写物流单号',
            trigger: 'blur'
          }
        ],
        receive_phone: [
          {
            required: true,
            message: '请填写收件人手机号',
            trigger: 'blur'
          },
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error('请填写收件人手机号'));
              } else if (!RegExp.mobile.test(value)) {
                callback(new Error('手机号格式不正确'));
              } else {
                callback();
              }
            },
            trigger: 'blur'
          }
        ],
      },
      expressCompany: []
    };
  },

  mounted () {
    this.GET_GoodsAuditList();
    this.getExpressCompany();
  },
  activated () {
    this.GET_GoodsAuditList();
    this.$store.commit("DEL_VISITED_VIEWS", this.$route);
  },
  watch: {
    'goodsAuditForm.pass': function (newVal) {
      this.goodsAuditRules.reason[0].required = newVal === 0;
    }
  },
  methods: {
    openDetail (row) {
      this.$store.dispatch("addVisitedViews", this.$route);
      this.$router.push({
        name: "cardLookInfoReview",
        params: {
          id: row.id,
        },
      });
    },
    // 文件上传
    beforeUpload (file) {
      const file_type = file.type.indexOf('jpg') > -1 || file.type.indexOf('jpeg') > -1 || file.type.indexOf('png') > -1 || file.type.indexOf('pdf') > -1
      if (file.size > 1024 * 1024 * 5) {
        this.$message.error('文件大小不能超过5M')
        return false
      }
      if (!file_type) {
        this.$message.error('请上传jpg,jpeg,png,pdf格式的文件')
      }
      return file_type
    },
    uploadSuccess (res, file, fileList) {
      let obj = {}
      obj['file_type'] = res.ext
      obj['url'] = res.url
      obj['file_name'] = file.name
      console.log(obj);

      this.fileList.push(obj)
      this.goodsAuditForm.files.push(res.url)
    },
    // 删除本地存储的文件
    removeFile (index) {
      this.fileList.splice(index, 1)
      this.goodsAuditForm.files.splice(index, 1)
    },
    /** 搜索事件触发 */
    searchEvent (data) {
      this.params.page_no = 1;
      this.GET_GoodsAuditList();
    },
    /**查询物流公司列表 */
    getExpressCompany () {
      API_Account.getExpressCompany().then(res => {
        this.expressCompany = res
      })
    },
    /**物流公司下拉选择 */
    handleExpressCompanyChange (v) {
      this.expressCompany.forEach((item, index) => {
        if (item.logi_id == v) {
          this.expressForm.logi_name = item.name
        }
      })
    },
    // 打开邮寄弹窗
    handleExpress (row) {
      this.expressForm = {
        logi_id: '',
        logi_name: '',
        delivery_no: '',
        record_id: ''
      }
      this.expressForm.record_id = row.id
      this.expressDialog.display()
    },
    /**提交邮寄 */
    submitExpressForm () {
      let { promise, resolve, reject } = Promise.withResolvers();
      this.$refs['expressForm'].validate(valid => {
        if (valid) {
          API_SettingCustomer.deliveryCardInvoice(this.expressForm.record_id, this.expressForm).then(res => {
            this.$message.success('提交成功')
            this.GET_GoodsAuditList()
            this.$refs['expressForm'].resetFields()
            this.expressForm.logi_name = ''
            this.expressForm.history_id = ''
            this.dialogExpressVisible = false
            resolve(true)
          })
        } else {
          reject(false)
        }
      })
      return promise
    },

    //格式化时间控件
    handleDateChange () {
      if (
        this.order_time_range &&
        this.order_time_range.length
      ) {
        if (typeof this.order_time_range[0] === "string") {
          this.params.start_time =
            new Date(this.order_time_range[0]).getTime() / 1000;
          this.params.end_time =
            new Date(this.order_time_range[1]).getTime() / 1000 +
            86400;
        } else {
          this.params.start_time =
            this.order_time_range[0].getTime() / 1000;
          this.params.end_time =
            this.order_time_range[1].getTime() / 1000 + 86400;
        }
      } else {
        this.params.start_time = "";
        this.params.end_time = "";
      }
    },

    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.GET_GoodsAuditList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_GoodsAuditList();
    },

    /** 打开审核商品 */
    handleGoodsAudit (row) {
      this.goodsAuditForm.invoice_type = row.invoice_type;
      this.goodsAuditForm.record_id = row.id;
      this.goodsAuditForm.pass = 1;
      this.goodsAuditForm.reason = '';
      this.fileList = []
      this.goodsAuditForm.files = []
      this.examinationDialog.display();
    },

    /** 审核商品 表单提交 */
    submitGoodsAuditForm () {
      const { promise, resolve, reject } = Promise.withResolvers()
      this.$refs['goodsAuditForm'].validate(valid => {
        if (valid) {
          const form = {
            ...this.goodsAuditForm
          }
          form.files = form.files.toString();
          API_SettingCustomer.checkCardInvoice(form).then(response => {
            this.$message.success('审核完成！');
            this.GET_GoodsAuditList();
            resolve(true)
          }).catch(err => reject(false))
        } else {
          reject(false)
        }
      });
      return promise
    },

    /** 获取开卡审核列表 */
    GET_GoodsAuditList () {
      this.loading = true;
      let params = {
        ...this.params,
        ...this.advancedForm
      };
      params[this.key_word] = params.keyWord
      delete params.keyWord
      API_SettingCustomer.getCardInvoiceList(params)
        .then(response => {
          this.loading = false;
          this.tableData = response;
        })
        .catch(() => (this.loading = false));
    },
  }
};
</script>

<style lang="scss" scoped>
.goods-image {
  width: 50px;
  height: 50px;
}

.headerStep {
  display: flex;

  p {
    margin: 0
  }

  .stepImage {
    display: flex;
    flex-direction: column;
    width: 200px;
    justify-content: center;
    align-items: center;

    img {
      width: 70px;
    }

    span {
      margin-top: 15px;
    }
  }

  .stepLine {
    display: flex;
    align-items: center;
    margin: 0 10px 39px;

    .line {
      display: inline-block;
      width: 80px;
      height: 4px;
      background: #333;
    }

    .arrow-right {
      display: inline-block;
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 12px solid transparent;
      border-left: 10px solid #333;
    }
  }
}

.goods-picker-title {
  border-bottom: 1px solid #ccc;
  height: 51px;
  line-height: 51px;
  text-align: left;
  background-color: rgba(224, 228, 238, 1);
  font-weight: 600;
  border-radius: 5px;
}

.goods-picker-title span {
  background-color: #1a43a9;
  width: 4px;
  height: 14px;
  display: inline-block;
  margin-right: 14px;
  margin-bottom: 3px;
}

.el-radio {
  margin-top: 6px;
}

.fileName {
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}

.imgBox {
  width: 90px;
  height: 109px;
  margin-right: 10px;
  position: relative;

  .el-icon-circle-close {
    position: absolute;
    right: -5px;
    top: -5px;
    font-size: 16px;
    color: #f56c6c;
    cursor: pointer;
  }
}
</style>
